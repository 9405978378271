<script setup lang="ts">
import { useCompanyStore } from '@/stores/companies'
import { useUserStore } from '@/stores/user'

const companiesStore = useCompanyStore()
const userStore = useUserStore()

const route = useRouter().currentRoute.value
const { $api, $userCan } = useNuxtApp()

const {
  pending: companyPending,
  data: company,
  error: companyError,
} = await useLazyAsyncData('sidebar-company', async () => {
  const response = await $api.core.api.getCompanyById(
    route.params.companyId as unknown as number,
  )

  return response.data
})

const {
  pending: debitCreditPending,
  data: discountCreditObject,
  error: debitCreditError,
} = await useLazyAsyncData('sidebar-debitcredits', async () => {
  const creditResponse = await $api.core.api.getCredits(
    route.params.companyId as unknown as string,
    {
      companyId: route.params.companyId as unknown as number,
    },
  )
  const discountResponse = await $api.core.api.getDiscounts(
    route.params.companyId as unknown as string,
    {
      companyId: route.params.companyId as unknown as number,
    },
  )
  return {
    credits: creditResponse.data,
    discounts: discountResponse.data,
  }
})

watch(companyError, (newError) => {
  if (newError) {
    navigateTo('/company')
  }
})
// prettier-ignore
const currentCompanyUser = computed(() =>
  userStore.userDetails?.Companies?.find(
    (x) => x.CompanyId?.toString() === route.params.companyId,
  ),
)
</script>

<template>
  <div class="max-w-full min-h-screen flex flex-col bg-base-200">
    <slot name="admin-header">
      <ClientOnly>
        <ClientViewBar
          v-if="
            companiesStore.clientViewCompany &&
            companiesStore.clientViewCompany?.Id &&
            userStore.getSystemRole != 'client_user'
          "
        ></ClientViewBar>
      </ClientOnly>
    </slot>
    <slot name="header">
      <ClientOnly>
        <Header></Header>
      </ClientOnly>
    </slot>
    <slot name="progress" />
    <ClientOnly>
      <main class="container grid grid-cols-6 gap-4">
        <div v-if="!companyPending" class="sidebar mt-5">
          <h4 class="pl-5">{{ company?.CompanyName }}</h4>
          <ul class="menu w-full pt-0">
            <li
              v-if="
                $userCan(
                  'read',
                  'company/location',
                  parseInt(route.params.companyId as string),
                )
              "
            >
              <NuxtLink :to="`/company/${route.params.companyId}/`"
                >Details</NuxtLink
              >
            </li>
            <li
              v-if="
                $userCan(
                  'read',
                  'company/location',
                  parseInt(route.params.companyId as string),
                )
              "
            >
              <NuxtLink :to="`/company/${route.params.companyId}/locations`"
                >Locations</NuxtLink
              >
            </li>
            <li
              v-if="
                $userCan(
                  'read',
                  'company/users',
                  parseInt(route.params.companyId as string),
                )
              "
            >
              <NuxtLink :to="`/company/${route.params.companyId}/users`"
                >Users</NuxtLink
              >
            </li>
            <li
              v-if="
                $userCan(
                  'read',
                  'company/assets',
                  parseInt(route.params.companyId as string),
                )
              "
            >
              <NuxtLink :to="`/company/${route.params.companyId}/assets`"
                >Assets</NuxtLink
              >
            </li>
            <li
              v-if="
                $userCan(
                  'read',
                  'company/payment/method',
                  parseInt(route.params.companyId as string),
                ) && currentCompanyUser
              "
            >
              <NuxtLink :to="`/company/${route.params.companyId}/billing`"
                >Payment Methods</NuxtLink
              >
            </li>
            <li
              v-if="
                !debitCreditPending &&
                !debitCreditError &&
                discountCreditObject!.discounts.length > 0
              "
            >
              <NuxtLink :to="`/company/${route.params.companyId}/discounts`"
                >Discounts</NuxtLink
              >
            </li>
            <li
              v-if="
                !debitCreditPending &&
                !debitCreditError &&
                discountCreditObject!.credits.length > 0
              "
            >
              <NuxtLink :to="`/company/${route.params.companyId}/credits`"
                >Credits</NuxtLink
              >
            </li>
            <li
              v-if="
                $userCan(
                  'read',
                  'campaign/support',
                  parseInt(route.params.companyId as string),
                )
              "
            >
              <NuxtLink
                :to="`/company/${route.params.companyId}/campaignsupports`"
                >Campaign Support</NuxtLink
              >
            </li>
          </ul>
        </div>
        <div class="col-span-5">
          <slot />
        </div>
      </main>
    </ClientOnly>
    <slot name="footer">
      <Footer></Footer>
    </slot>
  </div>
</template>
